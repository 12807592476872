import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
// import { IconButton, Menu, MenuItem } from '@material-ui/core';
// import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles(theme => ({

    menu: {
        color: '#673ab7',
        float: 'right',
        marginTop: 40,
        marginRight: 40,
        fontSize: '16px'
    },
    menuItem: {
        color: '#673ab7',
        fontSize: '16px'
    },
    root: {
        display: 'flex',
    },
}));

function Header(props) {
    // console.log('header component :', props)
    // console.log('this.props.state.token :', props.propsState.user_type)
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(true);
    const anchorRef = React.useRef(null);

    const [menu, setMenu] = useState({ select: null });

    // React.useEffect(() => {
    //     // console.log('inside useEffect menu :', menu.select)
    //     // console.log('inside useEffect open :', open)
    //     if (open) {
    //         anchorRef.current.click()
    //     }
    // }, [menu.select]);

    function handleMenuOpen(event, menu) {
        // console.log('called handleMenuOpen event :', event)
        // console.log('called handleMenuOpen menu :', menu)
        // console.log('called handleMenuOpen event.currentTarget :', event.currentTarget)
        setMenu({
            ...menu,
            [menu]: event.currentTarget
        });
    }

    function handleMenuClose(event, menu) {
        // console.log('called handleMenuClose event : ', event)
        // console.log('called handleMenuClose menu : ', menu)
        setOpen(false);
        setMenu({
            ...menu,
            [menu]: null
        });
    }

    return (
        <React.Fragment>
            {
                (props.propsState.user_type === 'User' || props.propsState.user_type === 'Admin') &&
                <div>
                    {
                        (props.propsState.user_type === 'User' || props.propsState.user_type === 'Admin') &&

                        <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
                            {
                                dispatch({ type: 'CustomerLogout' })
                                window.location.href = "/"
                            }
                        }}>Sign Out</Button>
                    }

                    {
                        (props.propsState.user_type === 'Admin') &&

                        <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
                            {
                                props.history.push("/UsageReportChart")
                            }
                        }}>Usage Report</Button>
                    }
                    {
                        (props.propsState.user_type === 'Admin') &&

                        <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
                            {
                                props.history.push("/SalesReportChart")
                            }
                        }}>Sales Report</Button>
                    }
                    {
                        (props.propsState.user_type === 'User') &&

                        <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
                            {
                                props.history.push("/ContactUs")
                            }
                        }}>Contact Us</Button>
                    }
                    {
                        (props.propsState.user_type === 'User') &&

                        <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
                            {
                                props.history.push("/Profile", { PropsData: 'edituser' })
                            }
                        }}>Edit Profile</Button>
                    }
                    {
                        (props.propsState.user_type === 'Admin') &&

                        <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
                            {
                                props.history.push("/AccountList")
                            }
                        }}>Account List</Button>
                    }

                </div>
            }
        </React.Fragment>
    );
}

export default withRouter(Header);
