
import React, { Component } from 'react';
import { amber, blue } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import notificationPng from '../assets/notification.png';
import clsx from 'clsx';

const styles = theme => ({
	root: {},
	success: {
		backgroundColor: '#32CD32',
		color: '#FFFFFF'
	},
	error: {
		backgroundColor: '#E45048',
		color: '#FFFFFF'
	},
	info: {
		backgroundColor: blue[600],
		color: '#FFFFFF'
	},
	warning: {
		backgroundColor: amber[600],
		color: '#FFFFFF'
	}
});

class SafeTechSnackbar extends Component {
    constructor(props) {
		super(props)
        this.state = {}
        // console.log('this SafeTechSnackbar props ::',this.props);
    }
    render() {
        const { classes } = this.props;
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={this.props.snack}
                autoHideDuration={2000}
                onClose={() => { this.props.closeSnack() }}
                >
                <SnackbarContent
                    className={clsx(classes[this.props.variant])}
                    message={
                        <div className="flex items-center">
                            <img className="h-5 px-2" src={notificationPng} alt='notification'/>
                            <span className="font-hairline text-sm">{this.props.snackMsg}</span>
                        </div>
                    }
                />
            </Snackbar>
            );
    }
}

export default (withStyles(styles)(SafeTechSnackbar));