import './css/App.css';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
});

class SuccessForgotPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            snack: false,
            spinner: 'none',
            BUTTONS: true
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <Grid container>
                    <Grid item xs />
                    <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                        <div style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '30px 20px' }}>
                            <form noValidate>
                                <p style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16, textAlign : 'center' }}>A temporary password was sent.<br/>Please use that code to login and reset your new password.</p>
                                {this.state.BUTTONS &&
                                    <Grid container>
                                        <Button className={classes.Margin} onClick={() => this.props.history.push("")} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Sign In
                                        </Button>
                                        <Button className={classes.Margin} onClick={() => this.props.history.push("/AdminApproval")} style={{ fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#673ab7', paddingTop: 10, paddingBottom: 10, marginTop: 10, textTransform: 'none', fontSize: '16px' }}>
											Sign Up
										</Button>
                                    </Grid>}
                                <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                    <center><CircularProgress className={classes.progress} /></center>
                                </div>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs />

                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SaveAdminToken: (token) => dispatch({ type: 'SaveAdminToken', value: token })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SuccessForgotPassword));