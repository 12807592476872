import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/app.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { createStore } from "redux";
import safe_tech_reducer from "./store/reducer";
import { Provider } from "react-redux";
import WebFont from "webfontloader";

WebFont.load({
  google: {
    families: ["Roboto:500", "sans-serif"]
  }
});
//create Store
const STORE = createStore(safe_tech_reducer);

ReactDOM.render(
  <Provider store={STORE}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
