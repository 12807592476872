import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
import SafeTechSnackbar from './SafeTechSnackbar';
import clsx from 'clsx';
import successPng from '../assets/success.png';
import progressPng from '../assets/progress.png';
import mail from '../assets/mail.png';
import phone from '../assets/phone.png';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import PublicHeader from './PublicHeader';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '96%',
        padding: '0px 10px',
        //margin: '10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    forgotPasswordBackground:{
        background: 'transparent linear-gradient(102deg, #3E61C4 0%, #7D432D 100%) 0% 0% no-repeat padding-box',
        opacity: 1
    }
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            phone: '',
            email: '',
            type: '',
            snackMsg: '',
            snackVariant: 'error',
            snack: false,
            spinner: 'none',
            BUTTONS: true,
            modalOpen: false,
        }
    }

    componentDidMount() {
		if (this.props.state.token !== '') {
			if (this.props.state.user_type == 'Admin') {
				// window.location.href = "/AccountListing"
				window.location.href = "/AccountList"
			}
			else {
				window.location.href = "/MainMenu"
			}
		}
    }
    
    inputHandler = (event, inputID) => {
		var value = event.target.value
		if (inputID == "phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ phone: value })
				}
			}
		}
		else if (inputID == "email") {
            this.setState({ email: value })
        }
	}

    FormSubmit = (event) => {
        event.preventDefault();
        if ((this.state.phone !== '' && this.state.email == '') || (this.state.phone == '' && this.state.email !== '') ) {
            if (this.state.email !== ''){
                if(!this.validateEmail(this.state.email)) {
                    this.setState({ snackMsg: "Email should be valid Email Address.", snack: true, snackVariant: 'error' })
                }else{
                    this.ForgotPassword()
                }
            }else{
                this.ForgotPassword()
            }
        } else {
            this.setState({ snackMsg: "Please enter Phone number or Email id.", snack: true, snackVariant: 'error' })
        }
    }

    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async ForgotPassword() {
        if (navigator.onLine) {
            if(this.state.phone !== ''){
                var Data = {
                    phone_no: this.state.phone,
                    email: ''
                }
            }
            else if(this.state.email !== ''){
                var Data = {
                    phone_no: '',
                    email: this.state.email
                }
            }
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.ForgotPassword, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    //console.log(res)
                    if (res.status) {
                        console.log('rest password send successfully!!');
                        this.setState({ modalOpen: true})
                        // window.location.href="/SuccessForgotPassword"
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                    }
                })
                .catch((error) => {
                    //console.log("Error==>" + error)
                    this.setState({ spinner: 'none', BUTTONS: true })
                    this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    handleClose = () => {
		this.setState({ modalOpen: false})
    }

    hideSnackBar = () => {
		this.setState({ snack: false })
	}

    render() {
        const { classes } = this.props;
        return (
            <div className={clsx(classes.forgotPasswordBackground, 'h-full')}>
                {/* <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.snack}
                    autoHideDuration={1500}
                    onClose={() => this.setState({ snack: false })}>
                    <SnackbarContent
                        className="error"
                        aria-describedby="client-snackbar"
                        message={this.state.snackMsg}
                    />
                </Snackbar> */}
                <SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
                <Dialog maxWidth={'xs'} fullWidth={false} open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <Grid container>
                            <div className="flex flex-col w-full">
                                <div className="text-center">
                                    <img className="h-8 mx-auto" src={successPng} alt="success-png"/>
                                </div>
                            </div>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <div className="font-semibold w-64">
                            <p className="text-primary text-center">A temporary password was sent.</p>
                            <p className="text-primary text-xs text-center px-2">Please use the temporary code to login and reset your new password.</p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <div className="flex flex-col w-full">
                                <div className="text-center">
                                    <button
                                        type="button"
                                        className="bg-primary w-40 text-white px-4 py-3 my-2 shadow-xl rounded text-base"
                                        onClick={() => {
                                            this.handleClose();
                                            this.props.history.goBack();
                                        }}>Return to Sign In
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <PublicHeader propsState={this.props.state}/>
				<div className="w-full h-screen max-w-2xl mx-auto mt-30p xl:mt-32">
                    <div className="flex flex-col lg:flex-row items-center py-4">
						<div className="w-8/12">
                            <div className="forgot-password-div text-white tracking-normal pl-4 py-12">
                                <p className="text-lg font-medium mb-6">
                                    Resetting your password is simple
                                </p>
                                <p className="text-base mb-3">1. Enter your phone number or email</p>
                                <p className="text-base mb-3">2. Send a temporary code</p>
                                <p className="text-base mb-3">3. Reset your password</p>
                            </div>
						</div>
                        <div className="w-8/12">
					        <form noValidate autoComplete="off" onSubmit={this.FormSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8">
                                <div>
                                    <div className="text-xl mx-auto mb-4 text-primary text-center">Reset Password</div>
                                </div>
                                <div className="mb-2">
                                    <div className="flex items-center border-solid border-2 rounded">
                                        <IconButton disabled={true} className="px-4 my-6" aria-label="phone">
                                            <img src={phone}/>
                                        </IconButton>
                                        <InputBase
                                            className="flex"
                                            type="tel"
                                            placeholder="Phone"
                                            value={this.state.phone}
                                            onChange={(event) => this.inputHandler(event, "phone")}
                                            inputProps={{ 'aria-label': 'phone' }}
                                        />
                                    </div>
                                </div>
                                <p className="text-center text-primary font-light text-xs">Or</p>
                                <div className="mb-4 mt-2">
                                    <div className="flex items-center border-solid border-2 rounded">
                                        <IconButton disabled={true} className="pl-4 my-6" aria-label="mail">
                                            <img src={mail}/>
                                        </IconButton>
                                        <InputBase
                                            className="flex"
                                            type="email"
                                            placeholder="Email"
                                            value={this.state.email}
                                            onChange={(event) => this.inputHandler(event, "email")}
                                            inputProps={{ 'aria-label': 'email' }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-between">
                                    <button
                                        type="submit"
                                        disabled={(this.state.spinner === 'block')}
                                        className="w-full shadow-2xl bg-primary hover:bg-secondary
                                        transition ease-in duration-200
                                        border text-white text-base py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline">
                                        {(this.state.spinner === 'block') ? <img className="h-6 mx-auto" src={progressPng} alt='progress'/> : 'Set Temporary Code' }
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-block align-baseline text-sm text-safeGray hover:text-primary"
                                        onClick={() => this.props.history.goBack()}
                                    >or back to sign in</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <Grid container>
                    <Grid item xs />
                    <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                        <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '30px 20px' }}>
                            <form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
                            <TextField
									id="phone"
									autoFocus={true}
									label="Phone #"
									className={classes.Margin}
									type="tel"
									margin="normal"
									style={{ width: '100%' }}
									value={this.state.phone}
									onChange={(event) => this.inputHandler(event, "phone")}
									variant="outlined"
                                    // InputProps={{
                                    // startAdornment: <InputAdornment position="start" style={{ background: '#32CD32' }}><img src={SMS} alt="sms" style={{ height: '80%', width: '60%'}} /></InputAdornment>,
                                    // </InputAdornment>,
                                    // }}
								/>
                                <b style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16, textAlign : 'center' }}>Or</b>
								<TextField
                                        id="email"
                                        label="Email"
                                        className={classes.Margin}
                                        type="email"
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        value={this.state.email}
                                        onChange={(event) => this.inputHandler(event, "email")}
                                        variant="outlined"
                                        // InputProps={{
                                        // startAdornment: <InputAdornment position="start">Email</InputAdornment>,
                                        // }}
                                />

                                {this.state.BUTTONS &&
                                    <Grid container>
                                        <Button type="submit" variant="contained" style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Send Temporary Code
                                        </Button>
                                        <Button variant="contained" onClick={() => this.props.history.goBack()} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Back
                                        </Button>
                                    </Grid>}
                                <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                    <center><CircularProgress className={classes.progress} /></center>
                                </div>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs />
                </Grid> */}
            </div>
        );
    }
}

ForgotPassword.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SaveAdminToken: (token) => dispatch({ type: 'SaveAdminToken', value: token })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));
